import React, { useState, useRef, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import ProfileVideoItem from "../components/profile-video-item"
// import ImageGallery from "../components/image-gallery"
import SEO from "../components/seo"

const Single = ({ pageContext, data }) => {
  const model = data.allMysqlModels.nodes[0]
  const video = pageContext.videoInfo
  const [isCopying, setIsCopying] = useState(false)
  const [email, setEmail] = useState("")
  const [follow, setFollow] = useState(false)
  const [swSupport, setSwSupport] = useState(false)
  const stickTo = useRef(null)
  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      console.log("alright i can do push notifications")
      setSwSupport(true)
      // navigator.serviceWorker
      //   .register("sw.js")
      //   .then(function(swReg) {
      //     console.log("Service Worker is registered", swReg);

      //     swRegistration = swReg;
      //     initializeUI();
      //   })
      //   .catch(function(error) {
      //     console.error("Service Worker Error", error);
      //   });
    }
  }, [])
  const handleFollower = () => {
    //If can do push notifications attempt to do that, too.
    console.log("going to send this back to the server")
  }
  const copyToClipboard = () => {
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 5000)

    if (!navigator.clipboard) {
      //fallbackCopyTextToClipboard(text);
      var textArea = document.createElement("textarea")
      textArea.className = "textareahide"
      textArea.value = `https://addfriends.com/${pageContext.canURL}`
      stickTo.current.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
      } catch (err) {
        console.error("Could not copy!", err)
      }
      stickTo.current.removeChild(textArea)
      return
    }
    navigator.clipboard
      .writeText(`https://addfriends.com/${pageContext.canURL}`)
      .then(
        function() {
          console.log("Async: Copying to clipboard was successful!")
        },
        function(err) {
          console.error("Async: Could not copy text: ", err)
        }
      )
  }
  return (
    <Layout>
      <SEO
        title={`${video.title} - ${model.model_name}`}
        description={`${video.blog}`}
        can={pageContext.canURL}
      />
      <div className="main">
        <div className="feed">
          <div className="poster">
            <Image
              alt={model.model_name}
              filename={`${model.model_url}-header.jpg`}
            />
            <div className="inform">
              <div className="container">
                <ul className="list">
                  <li className="item">
                    Last active <span>28 April</span>
                  </li>
                  <li className="item">
                    <span>4,789</span>m views
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section_twocolumns">
            <div className="section_aside">
              <div className="section_information">
                <div className="wrap">
                  <div
                    className="avatar"
                    onClick={() => {
                      if (model.af_site_id !== 0) {
                        navigate(`/profile/${model.model_url}`)
                      }
                    }}
                  >
                    <i className="icon-accept-circle"></i>
                    <Image
                      alt={model.model_name}
                      filename={`${model.model_url}-avatar.jpg`}
                    />
                    {/* Going to need to createt his thumb for everyone */}
                  </div>
                </div>
                <div className="inform">
                  <div className="name on">
                    <strong>{model.model_name.split(" ")[0]}</strong>
                    {model.model_name.split(" ")[1]}
                  </div>
                  <div className="email">
                    @<strong>{model.model_name.split(" ")[0]}</strong>
                    {model.model_name.split(" ")[1]}
                  </div>
                  <div className="state">
                    <i className="icon-pin"></i>
                    <span>{model.location}</span>
                  </div>
                  <ul className="social">
                    {model.facebook_url ? (
                      <li className="item">
                        <a
                          href={`https://www.facebook.com/${model.facebook_url}/`}
                        >
                          <i className="icon-fb"></i>
                        </a>
                      </li>
                    ) : null}
                    {model.twitter_url ? (
                      <li className="item">
                        <a href={`https://twitter.com/${model.twitter_url}`}>
                          <i className="icon-twitter"></i>
                        </a>
                      </li>
                    ) : null}

                    {model.snapchat_url ? (
                      <li className="item">
                        <a
                          href={`https://www.snapchat.com/add/${model.snapchat_url}`}
                        >
                          <i className="icon-snapchat"></i>
                        </a>
                      </li>
                    ) : null}

                    {model.instagram_url ? (
                      <li className="item">
                        <a
                          href={`https://www.instagram.com/${model.instagram_url}/`}
                        >
                          <i className="icon-inst"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              {/* <div className="section_items">
                  <h3 className="title">
                    Other <strong>Available Models</strong>
                  </h3>
                  <ul className="list_avatars">
                    <li className="item">
                      <a href="#" className="avatar_min on">
                        <img src="images/content/avatar.jpg" alt="" />
                      </a>
                    </li>
                    <li className="item">
                      <a href="#" className="avatar_min on">
                        <img src="images/content/avatar.jpg" alt="" />
                      </a>
                    </li>
                    <li className="item">
                      <a href="#" className="avatar_min">
                        <img src="images/content/avatar.jpg" alt="" />
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="button">
                    <span>Show All</span>
                  </a>
                </div> */}
            </div>
            <div className="section_content">
              <div className="section_panel">
                <div className="column" ref={stickTo}>
                  <div className="share_button" onClick={copyToClipboard}>
                    {isCopying ? (
                      <>
                        <i className="icon-accept-circle"></i>
                        <span>Copied!</span>
                      </>
                    ) : (
                      <>
                        <i className="icon-arrow-back"></i>
                        <span>Share</span>
                      </>
                    )}
                  </div>
                  <div className={follow ? "link_field contain" : "link_field"}>
                    <i className="icon-link"></i>
                    <a href={`https://addfriends.com/${pageContext.canURL}`}>
                      www.addfriends.com/{pageContext.canURL}
                    </a>
                  </div>
                </div>
                <div className="column second">
                  <div
                    className={follow ? "follow_field open" : "follow_field"}
                  >
                    <button
                      type="button"
                      className="button_follow"
                      onClick={() => setFollow(true)}
                    >
                      <span>Follow</span>
                    </button>
                    <div className="follow_form">
                      <div className="field">
                        <div className="wrap">
                          <div className="photo">
                            <img
                              src={`https://static.addfriends.com/images/${model.model_url}.jpg`}
                              alt={model.model_name}
                            />
                          </div>
                          <input
                            type="email"
                            className="input"
                            value={email}
                            placeholder="john@example.com"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="button_submit"
                          onClick={handleFollower}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ProfileVideoItem
                video={video}
                key={`pvi${video.mysqlId}`}
                model_url={model.model_url}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($modelID: Int!) {
    allMysqlModels(filter: { mysqlId: { eq: $modelID } }) {
      nodes {
        af_site_id
        blog
        followers
        instagram_url
        location
        model_name
        model_url
        pics
        snapchat_url
        twitter_url
        videos_count
      }
    }
  }
`
export default Single
