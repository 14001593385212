import React from "react"
import { Link } from "gatsby"
const ProfileVideoItem = ({ video, model_url }) => {
  const url = `/profile/${model_url}/${video.title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[&\/\\#,+()$~%.'":*?<>{}!]/g, "")}`
  return (
    <div className="section_box">
      <div className="icon">
        <div className="circle siteLogo">
          <a
            href={video.promo_sites.link.link}
            rel="nofollow noreferrer noopener"
            target="_blank"
          >
            <img
              src={`/videos/${video.promo_sites.site_url}.jpg`}
              alt={video.promo_sites.site_name}
            />
          </a>
        </div>
      </div>
      <div className="content_box">
        <h1 className="profileTitle">
          <Link to={`${url}`}>{video.title}</Link>
        </h1>
        <div className="desc">
          {video.blog} -{" "}
          <a
            href={video.promo_sites.link.link}
            target="_blank"
            rel="nofollow noreferrer noopener"
          >
            See More
          </a>
        </div>
        <div className="videoContainer">
          <video
            preload="none"
            controls
            playsInline={true}
            src={`https://static.addfriends.com/profile/videos/${video.file}`}
            poster={`https://static.addfriends.com/profile/videos/${video.file.replace(
              ".mp4",
              ".jpg"
            )}`}
          ></video>
        </div>
      </div>
    </div>
  )
}

export default ProfileVideoItem
